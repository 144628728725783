export const checkboxStyles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left'
    },
    checkbox: {
        minWidth: 24
    }
};
