import React from 'react';

import cn from 'classnames';
import { createUseStyles } from 'react-jss';

import { useAdditionalNodes } from '../../hooks/use_additional_nodes';
import { useReceivedGlobalClasses } from '../../hooks/use_received_global_classes';

import { styles } from './avatar_styles';
// import picture from '../../../../package/assets/images/60671651_100484527870277_3495812622542962688_o.png';
const picture = require('../../../../package/assets/images/60671651_100484527870277_3495812622542962688_o.png');

const useStyles = createUseStyles(styles);

const AvatarComponent = ({ displayedName }) => {
    const classes = useStyles();
    const [receivedGlobalClasses] = useReceivedGlobalClasses('banner.avatar');
    const [nodes] = useAdditionalNodes('banner.avatar', null);
    return (
        <div className={cn(classes.container, receivedGlobalClasses.container)}>
            <div className={cn(classes.imageContainer, classes.imageContainer)}>
                <img className={cn(classes.image, receivedGlobalClasses.image)} src={picture} alt={displayedName} />
            </div>
            {nodes}
        </div>
    );
};

export const Avatar = AvatarComponent;
