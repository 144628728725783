export const LOADING_SPINNER_TRANSITIONS = {
    from: {
        opacity: 0
    },
    enter: {
        opacity: 1
    },
    leave: {
        opacity: 0
    }
};
